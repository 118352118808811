import React, { useState, useEffect } from "react";
import httpClient from "../httpClient";
import FileList from "./components/FilesList";
// import "../file_page.css";
import styled from 'styled-components';

const Page1Container = styled.body`
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

.body {
    font-family: var(--body-font);
    color: var(--body-color);
    background-image: url(/bg_pic.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color-dodge;
    background-color: rgba(18, 21, 39, 0.86);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    --body-font: "Inter", sans-serif;
    --theme-bg: #1f1d2b;
    --body-color: #808191;
    --button-bg: #353340;
    --border-color: rgb(128 129 145 / 24%);
    --video-bg: #252936;
    --delay: 0s;
}

.body:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(163deg, #1f1d2b 21%, rgba(31, 29, 43, 0.3113620448) 64%);
    opacity: 0.4;
    content: "";
}

.container {
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background-color: var(--theme-bg);
    /* max-width: 1600px; */
    height: 90vh;
    display: flex;
    overflow: hidden;
    width: 95vw;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 500;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
    position: relative;
}

.sidebar {
    width: 220px;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transition-duration: 0.2s;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar .logo {
    display: none;
    width: 30px;
    height: 30px;
    background-color: #22b07d;
    flex-shrink: 0;
    color: #fff;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.sidebar .logo-expand {
    text-decoration: none;
    color: #fff;
    font-size: 19px;
    font-weight: 600;
    line-height: 34px;
    position: sticky;
    top: 0;
}

.sidebar .logo-expand:before {
    content: "";
    position: absolute;
    top: -30px;
    left: 0;
    background: var(--theme-bg);
    width: 200px;
    height: 70px;
    z-index: -1;
}

.sidebar-link:hover,
.sidebar-link.is-active {
    color: #fff;
    font-weight: 600;
}

.side-menu {
    display: flex;
    flex-direction: column;
}

.side-menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--body-color);
    margin-top: 26px;
}

.side-menu svg {
    width: 30px;
    padding: 8px;
    border-radius: 10px;
    background-color: var(--button-bg);
    flex-shrink: 0;
    /* margin-left: 16px; */
    align-self: right;
    margin-left: auto;
}

.side-title {
    font-size: 12px;
    letter-spacing: 0.07em;
    margin-bottom: 24px;
}

.side-wrapper {
    border-bottom: 1px solid var(--border-color);
    padding: 36px 0;
    width: 145px;
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 5px;
}


.centered-container {
    display: table;
    width: 100%;
    height: 100%;
  }
  
.stub-label {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
  }

  h2 {
    background-image: linear-gradient(
      to right,
      #8B27B0,
      #8B27B0 50%,
      #000 50%
    );
    cursor: pointer;
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(220,220,220,0.5);
    transition: all 0.3s ease-in-out;
    color: white;
  }
  
  h2:before{
    content: '';
    background: #8B27B0;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  h2:hover {
   background-position: 0;
  }
  
  h2:hover::before{
    width: 100%;
  }
`;

const FileDownload: React.FC = () => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [currentFile, setCurrentFile] = useState<string | null>(null);
    const [publicUrl, setPublicUrl] = useState<string | null>(null);


    const get_file_url = async () => {
        try {
            console.log("getting url...");
            console.log(currentFile);
            if (!currentFile) {
                console.log("No file");
                return;
            }

            var fileName = btoa(encodeURIComponent(currentFile));

            try {
                const response = await httpClient.get("https://pdf-scanner-server.onrender.com/get_url", {
                    headers: { "file": fileName }
                });
                await setPublicUrl(window.location.origin + "/" + response.data.url);
                console.log(response.data.url);
                navigator.clipboard.writeText(window.location.origin + "/" + response.data.url);
            } catch (error) {
                console.log("No url");
            }
        } catch (error: any) {
            console.log('Error getting public url:', error);
            // alert("Error n " + error.response?.status + " " + error.response);

        }
    };

    const downloadFile = async () => {
        try {
            console.log("Downloading file...");
            console.log(currentFile);
            if (!currentFile) {
                console.log("No file selected");
                return;
            }

            var fileName = btoa(encodeURIComponent(currentFile));
            const response = await httpClient.get("https://pdf-scanner-server.onrender.com/download", {
                responseType: 'blob', // Important: Set the response type to 'blob'
                headers: { "file": fileName }
            });

            // Create a URL for the blob
            const pdfBlobUrl = URL.createObjectURL(response.data);
            setPdfUrl(pdfBlobUrl);

        } catch (error: any) {
            console.log('Error fetching files list:', error);
            alert("Error downloading file aaaaaaa " + error.response?.status + " " + error.response);

        }
    };
    useEffect(() => {
        if (currentFile) { // Check if currentFile is not null
            downloadFile();
        }
    }, [currentFile]);

    return (
        <Page1Container>
            {
                <body className="body">
                    <div className="container">
                        <FileList currentFile={currentFile} setCurrentFile={setCurrentFile} />
                        <div className="wrapper">
                            <div className="header">
                                {currentFile && <h2 onClick={get_file_url} >{currentFile}</h2>}
                            </div>
                            {pdfUrl != null ? (
                                <iframe
                                    src={pdfUrl}
                                    style={{ width: '100%', height: '100%' }}
                                    frameBorder="0"
                                />
                            ) : (
                                <div className="centered-container">
                                    <label className="stub-label">No file selected</label>
                                </div>
                            )

                            }
                        </div>

                    </div>
                </body>
            }
        </Page1Container >
    );
};

export default FileDownload;
