import React, { useState, useEffect } from "react";
import httpClient from "../httpClient";

const NotFound: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string >("");
  const [urlExists, setUrlExsists] = useState<Boolean | null>(null);

  useEffect(() => {
    (async () => {
      try {
        if (window.location.pathname.replace("/", "").length != 8) {
          setUrlExsists(false);
          return;
        }
        console.log("looking for : ", window.location.pathname.replace("/", ""));
        var looking_for_url = window.location.pathname.replace("/", "");
        const resp = await httpClient.post("https://pdf-scanner-server.onrender.com/url_exists", {
          looking_for_url
        });
        var fileExists = resp.data.url_exists;
        console.log(resp.data.url_exists);
        if (fileExists == 0) {
          setUrlExsists(false);
          return;
        }
        setUrlExsists(true);

        console.log("file exists!!!")
        downloadFileFromUrl();

      } catch (error) {
        console.log("error");
      }
    })();
  }, []);

  const downloadFileFromUrl = async () => {
    try {
      console.log("Downloading file from url...");
      console.log(window.location.pathname.replace("/", ""));

      const response = await httpClient.get("https://pdf-scanner-server.onrender.com/download_from_url", {
        responseType: 'blob', // Important: Set the response type to 'blob'
        headers: { "file": window.location.pathname.replace("/", "") }
      });

      // Create a URL for the blob
      const pdfBlobUrl = URL.createObjectURL(response.data);
      setPdfUrl(pdfBlobUrl);

    } catch (error: any) {
      console.log('Error fetching files list:', error);
      // alert("Error downloading file " + error.response?.status + " " + error.response);

    }
  };

  const renderContent = () => {
    if (urlExists === null) {
      return <h1></h1>;
    }
    if (urlExists) {
      return <iframe
        src={pdfUrl}
        style={{ width: '100vw', height: '100vh' }}
      />;
    }
    return <h1>404 not found</h1>;
  };

  return (
    <div className="main_layout">

    {renderContent()}

      {/* {urlExists === null ? (
        <h1></h1>
      ) : (

          urlExists ? (
            <iframe
              src={pdfUrl}
              style={{ width: '100vw', height: '100vh' }}
            />
          ) : (
            <h1>404 not found</h1>
          )
      )
      } */}


    </div >
  );
};

export default NotFound;
