import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import './particles.scss';
import './text-input.scss';
import Router from "./Router";



ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById("root")
);
// fuck it, just CI test