import React, { useState } from "react";
import httpClient from "../httpClient";

const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const registerUser = async () => {
    try {
      const resp = await httpClient.post("https://pdf-scanner-server.onrender.com/register", {
        email,
        password,
      });

      window.location.href = "/download";
    } catch (error: any) {
      if (error.response.status === 401) {
        alert("Invalid credentials");
      }
    }
  };

  return (
    <div className="main_layout">
      <h1>Create an account</h1>
 
      <form className="centered-container">
        <div>
          <div className="form__group field">
            <input id="email_input" type="email" className="form__field" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email_input" className="form__label">Email</label>
          </div>
        </div>

        <div className="form__group field">
          <input id="password_input" type="password" className="form__field" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="password_input" className="form__label">Password</label>
        </div>
        <button type="button" className="login-button" onClick={() => registerUser()}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default RegisterPage;
