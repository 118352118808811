import React, { useState, useEffect } from 'react';
import httpClient from "../../httpClient";
import { User } from "../../types";


interface FileListProps {
    currentFile: string | null;
    setCurrentFile: (file: string | null) => void;
}

const FileList: React.FC<FileListProps> = ({ currentFile, setCurrentFile }) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [files, setFiles] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const resp = await httpClient.get('https://pdf-scanner-server.onrender.com/@me', {
                    withCredentials: true  // This ensures cookies and other credentials are included with the request
                });
                setUser(resp.data);
            } catch (error) {
                console.log("Not authenticated");
            }
        })();
    }, []);

    useEffect(() => {
        const fetchFilesList = async () => {
            try {
                const response = await httpClient.get('https://pdf-scanner-server.onrender.com/files-list', {
                    withCredentials: true  // This ensures cookies and other credentials are included with the request
                });
                const data = await response.data;
                setFiles(data);
            } catch (error) {
                console.error('Error fetching files list:', error);
            }
        };

        fetchFilesList();

        const interval = setInterval(fetchFilesList, 15000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);




    const downloadFile = async () => {
        try {
            const response = await httpClient.get("https://pdf-scanner-server.onrender.com/download", {
                responseType: 'blob', // Important: Set the response type to 'blob'
                withCredentials: true
            });

            // Create a URL for the blob
            const pdfBlobUrl = URL.createObjectURL(response.data);
            setPdfUrl(pdfBlobUrl);

        } catch (error: any) {
            alert("Error downloading file" + error.response?.status);
        }
    };

    return (
        <div className="sidebar">
            <a className="logo-expand">Your files:</a>
            <div className="side-wrapper">
                <div className="side-menu">
                    {Object.keys(files).length === 0 ? (
                        <p>No files found</p>
                    ) : (
                        Object.entries(files).map(([fileName, fileDate]) => (
                            <a
                                className={`sidebar-link ${fileName === currentFile ? 'is-active' : ''}`}
                                key={fileName}
                                onClick={() => setCurrentFile(fileName)}
                            >
                                {fileName}
                                {/* Uncomment the following code if you want to add an SVG icon */}
                                {/* <svg viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                        d="M9.135 20.773v-3.057c0-.78.637-1.414 1.423-1.414h2.875c.377 0 .74.15 1.006.414.267.265.417.625.417 1v3.057c-.002.325.126.637.356.867.23.23.544.36.87.36h1.962a3.46 3.46 0 002.443-1 3.41 3.41 0 001.013-2.422V9.867c0-.735-.328-1.431-.895-1.902l-6.671-5.29a3.097 3.097 0 00-3.949.072L3.467 7.965A2.474 2.474 0 002.5 9.867v8.702C2.5 20.464 4.047 22 5.956 22h1.916c.68 0 1.231-.544 1.236-1.218l.027-.009z"
                                    />
                                </svg> */}
                            </a>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileList;
// 